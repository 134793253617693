import React, { useState, useEffect } from 'react';
import { urlFor } from '../sanity/sanityClient';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus, AiOutlineShopping } from 'react-icons/ai';
import { GoHome } from 'react-icons/go';
import { client } from '../sanity/sanityClient';
import { useStateContext } from '../context/StateContext';
import Cart from './Cart';
import NavBar from './NavBar';
import FetchCategory from './FetchCategory';
import ProductDetail from './ProductDetail';
import './Products.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [slugCategory, setSlugCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const { totalQuantities, showCart, setShowCart, onAdd } = useStateContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await client.fetch(
          '*[_type in [  "grocery", "beauty", "hotels", "estate", "clothingandfashion", "kitchen", "crops", "electronics", "jewelryandaccessories", "salon", "foodandbeverages", "sports",  "arts", "medical", "vehicles", "fashion"] && slug.current == $slug]',
          { slug }
        );
        setProducts(data);
        if (data.length > 0) {
          const category = data[0]._type;
          const categoryProducts = await client.fetch(`*[_type == $category]`, { category });
          setSlugCategory(categoryProducts.map((product) => ({
            ...product,
            currentImageIndex: 0,
            quantity: 1,
          })));
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
  }, [slug]);

  const handleFetchCategory = async (category) => {
    setShowCategory(false);
    try {
      const data = await client.fetch(`*[_type == '${category}']`);
      setSubCategories(data);
    } catch (error) {
      console.error(error);
    }
    navigate(`/category/${category}`);
  };

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };

   const handleAddToCart = (product) => {
  const focusedImage = product.image[product.currentImageIndex];
  const productWithFocusedImage = { ...product, image: [focusedImage] };
  onAdd(productWithFocusedImage, product.quantity);

  toast.success(`${product.quantity} ${product.name} added to the shopping bag.`, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
};


  const handlePrevImage = (index) => {
    setSlugCategory((prevState) =>
      prevState.map((product, i) =>
        i === index
          ? {
              ...product,
              currentImageIndex:
                product.currentImageIndex === 0
                  ? product.image.length - 1
                  : product.currentImageIndex - 1,
            }
          : product
      )
    );
  };

  const handleNextImage = (index) => {
    setSlugCategory((prevState) =>
      prevState.map((product, i) =>
        i === index
          ? {
              ...product,
              currentImageIndex:
                product.currentImageIndex === product.image.length - 1
                  ? 0
                  : product.currentImageIndex + 1,
            }
          : product
      )
    );
  };

  const incQty = (index) => {
    setSlugCategory((prevState) =>
      prevState.map((product, i) =>
        i === index ? { ...product, quantity: product.quantity + 1 } : product
      )
    );
  };

  const decQty = (index) => {
    setSlugCategory((prevState) =>
      prevState.map((product, i) =>
        i === index ? { ...product, quantity: Math.max(1, product.quantity - 1) } : product
      )
    );
  };

  return (
    <div className="productsPage" style={{ padding: '20px', textAlign: 'center' }}>
      <div className="NavPan" style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: '1000',
        top: '10px',
        paddingRight: '20px',
        backgroundColor: '#00000076',
        borderRadius: '10px',
      }}>
        <h3 style={{ color: 'white', left: '0' }}>
          <Link to={'/'} style={{ color: 'white', textDecoration: 'none' }}><GoHome />Home</Link>
        </h3>
        <div style={{ display: 'flex' }}>
          <h3><Link to="#" style={{ color: 'white', textDecoration: 'none' }}>Unboxing</Link></h3>
        </div>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}><Cart /></div>
        <div className={`modalTwo ${showMobileDropdown ? 'active' : ''}`}>
          <NavBar handleFetchCategory={handleFetchCategory} toggleMobileDropdown={toggleMobileDropdown} />
        </div>
      </div>

      <div className="productsDisplay">
        {showCategory ? (
          <ProductDetail products={products} />
        ) : (
          <FetchCategory subCategories={subCategories} />
        )}
      </div>

      <div className="category-products">
        {slugCategory.length > 0 ? (
          slugCategory.map((product, index) => (
            <div className="product-card" key={product._id}>
              <h3>{product.name}</h3>
              <div className="image-container"

             style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
                    }}
              >
                <img
                  src={urlFor(product.image[product.currentImageIndex])}
                  alt={product.name}
                  style={{ 
                width: '100%', 
                height: 'auto', 
                objectFit: 'cover',
                    }}
                  className="product-detail-image"
                />
                {product.image.length > 1 && (
                  <div className="slider-controls">
                    <button onClick={() => handlePrevImage(index)} className="slider-button prev-button">❮</button>
                    <button onClick={() => handleNextImage(index)} className="slider-button next-button">❯</button>
                  </div>
                )}
              </div>
              <p>{product.details}</p>
              <p className="price">Price: {product.price}</p>
              <div className="buttons">
                <span className="quantity" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#00000076', borderRadius: '10px' }}>
                  <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => decQty(index)}><AiOutlineMinus /></span>
                    <span style={{ margin: '0 10px' }}>{product.quantity}</span>
                    <span style={{ cursor: 'pointer' }} onClick={() => incQty(index)}><AiOutlinePlus /></span>
                  </span>
                  <button type="button" className="add-to-cart" onClick={() => handleAddToCart(product)}>
                    Add to <AiOutlineShopping />
                  </button>
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="load-note">Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Products;
