export const host = "https://swampa-b6eb7b1ba99b.herokuapp.com"; 
export const loginroute = `${host}/api/auth/login`;
export const registerroute = `${host}/api/auth/register`;
export const googleRegisterRoute=`${host}/api/auth/googleRegister`;
export const logoutroute = `${host}/api/auth/logout`;
export const allusersroute = `${host}/api/auth/allusers`;
export const onlineUsersRoute=`${host}/api/online-users`;
export const contactbymessages = `${host}/api/auth/contactsmessages`;
export const sendmessageroute = `${host}/api/messages/addmsg`;
export const recievemessageroute = `${host}/api/messages/getmsg`;
export const setavatarroute = `${host}/api/auth/setavatar`;
export const sendsurpriseroute=`${host}/api/surprises/addsurprise`;
export const receivesurpriseroute=`${host}/api/surprises/getsurprises`;
export const deletemessageroute=`${host}/api/messages/deleteMsgs`;
export const updatefcmtoken=`${host}/api/auth/update-fcm-token`;
export const requestReset=`${host}/api/auth/request-password-reset`;
export const reset=`${host}/api/auth/reset`;
export const allowUser=`${host}/api/auth/allow-user`;
export const rejectUser=`${host}/api/auth/reject-user`;
export const requestingusers=`${host}/api/auth/requestingusers`;
export const requesttosell=`${host}/api/auth/requesttosell`;
export const allowedusers=`${host}/api/auth/allowed-users`;

