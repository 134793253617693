import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Confetti from 'react-confetti';
import './Unboxing.css';

const Unboxing = () => {
    const [isOpened, setIsOpened] = useState(false);

    const handleOpen = () => {
        setIsOpened(true);
    };

    return (
        <div className="unboxing-container">
            {isOpened && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <div className="box" onClick={handleOpen}>
                {!isOpened ? (
                    <div className="box-content">Click to Open</div>
                ) : ( <div>
                    <div className="message">Coming Soon </div>
                        <p style={{fontSize:'20px', textAlign:'center'}}><Link to="/register" style={{ textDecoration: 'none', backgroundColor: "#007bff", color:'white' }}> Register </Link> to be notified!</p>
                    </div>


                )}
            </div>
        </div>
    );
};

export default Unboxing;
