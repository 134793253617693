import React from "react";
import { Link } from "react-router-dom";
import { GoX } from "react-icons/go";
import styled from "styled-components";

export default function CategorySwitch({ showCntcts }) {
  const categories = [
    { name: "Clothing", path: "/items/clothingandfashion" },
    { name: "Medical", path: "/items/medical" },
    { name: "Food", path: "/items/foodandbeverages" },
    { name: "Kitchen", path: "/items/kitchen" },
    { name: "Crops", path: "/items/crops" },
    { name: "Estate", path: "/items/estate" },
    { name: "Books", path: "/items/books" },
    { name: "Jewelry", path: "/items/jewelryandaccessories" },
    { name: "Fashion", path: "/items/fashion" },
    { name: "Grocery", path: "/items/grocery" },
    { name: "Toys", path: "/items/toys" },
    { name: "Beauty", path: "/items/beauty" },
    { name: "Sports", path: "/items/sports" },
    { name: "Arts", path: "/items/arts" },
    { name: "Vehicles", path: "/items/vehicles" },
    { name: "Furniture", path: "/items/homeandfurnitures" },
    { name: "Electronics", path: "/items/electronics" },
    { name: "Hotels", path: "/items/hotels" },
    { name: "Salon", path: "/items/salon" },
    { name: "Hardware", path: "/items/hardware" },
  ];

  return (
    <Container>
      <Header>
        <CloseIcon onClick={showCntcts} />
        <Title>Categories</Title>
      </Header>
      <LinkContainer>
        {categories.map(({ name, path }) => (
          <CategoryItem key={name}>
            <StyledLink to={path}>{name}</StyledLink>
          </CategoryItem>
        ))}
      </LinkContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f9f9;
  overflow-y: auto;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  background-color: #343a40;
  color: white;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const CloseIcon = styled(GoX)`
  cursor: pointer;
  font-size: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 15px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CategoryItem = styled.div`
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  color: #333333;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;
