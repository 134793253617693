import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar, AiOutlineShopping } from 'react-icons/ai';
import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetail = ({ products }) => {
  const [index, setIndex] = useState(0);
  const { decQty, incQty, qty, onAdd } = useStateContext();

  const handleAddToCart = (product) => {
  const focusedImage = product.image[index];
  const productWithFocusedImage = { ...product, image: [focusedImage] };
  onAdd(productWithFocusedImage, qty);

  toast.success(`${qty} ${product.name} with the selected image added to the shopping bag.`, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

  return (
    <div className="itemList">
      <ToastContainer />
      {products.map((product) => (
        <div 
          key={product._id} 
          style={{ 
            width: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            transition: 'transform 0.3s',
          }}
        >
          <h3 style={{ textAlign: 'center' }}>{product.name}</h3>
          <div 
            className="image-container" 
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={urlFor(product.image && product.image[index])}
              alt={product.name}
              style={{ 
                width: '100%', 
                height: 'auto', 
                objectFit: 'cover',
              }}
              className="product-detail-image"
            />
            <div 
              className="small-images-container" 
              style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(4, 1fr)', 
                gap: '10px', 
                width: '100%', 
                maxWidth: '1200px',
                margin: '10px 0',
              }}
            >
              {product.image.map((image, i) => (
                <span
                  key={i}
                  style={{ cursor: 'pointer' }}
                  className={i === index ? 'small-image selected-image' : 'small-image'}
                  onMouseEnter={() => setIndex(i)}
                >
                  <img
                    src={urlFor(image)}
                    alt={product.name}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </span>
              ))}
            </div>
          </div>
          <span style={{ display: 'block', margin: '10px 0' }}>
            <p style={{ color: 'red' }}>TZS:<strong>{product.price.toLocaleString()}/=</strong></p>
            <p>{product.details}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="reviews">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiOutlineStar />
                <p>(4)</p>
                <span className="quantity" style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ cursor: 'pointer' }} onClick={decQty}><AiOutlineMinus /></span>
                    <span style={{ margin: '0 10px' }}>{qty}</span>
                    <span style={{ cursor: 'pointer' }} onClick={incQty}><AiOutlinePlus /></span>
                  </span>
                </span>
              </div>
            </div>
            <div className="buttons" style={{ textAlign: 'center' }}>
              <button type="button" className="add-to-cart" onClick={() => handleAddToCart(product)}>
                Add to <AiOutlineShopping />
              </button>
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProductDetail;
